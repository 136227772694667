import React, { useState, FC, SetStateAction, Dispatch } from 'react'

import { PencilIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import { PROD_CLIENT } from 'api/src/common/enums'
import {
  DefaultHomeLinksQuery,
  type CreateInformerListCardTemplateMutation,
  type CreateInformerListCardTemplateMutationVariables,
  type UpdateInformerListCardTemplateMutation,
  type UpdateInformerListCardTemplateMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import {
  CREATE_CARD_TEMPLATE_ITEM,
  DELETE_CARD_TEMPLATE_ITEM,
  UPDATE_CARD_TEMPLATE_ITEM,
} from 'src/lib/queries/Settings/Layout/SettingsLayout'

import Button from '../Library/Button/Button'
import { CardAdminModal } from '../SettingsLayoutCard/SettingsLayoutCardStackModals/SettingsLayoutCardStackModals'

type DefaultCard = DefaultHomeLinksQuery['defaultHomeLinks'][0]
type Icon = DefaultHomeLinksQuery['globalIcons'][0]

interface DefaultHomeLinksProps {
  cards?: DefaultCard[]
  refetch?: () => Promise<unknown>
  globalIcons?: Icon[]
}

interface DefaultCardsByFirstLetter {
  [key: string]: DefaultCard[]
}

interface CardsGroupedByLetterProps {
  cards: DefaultCardsByFirstLetter
  setModalAction: Dispatch<SetStateAction<string>>
  setSelectedCard: Dispatch<SetStateAction<DefaultCard>>
  setCardAdminModalOpen: Dispatch<SetStateAction<boolean>>
}

const NoCardsFound = () => {
  return (
    <p className="w-full py-20 text-center text-gray-400">
      No default cards found.
    </p>
  )
}

const CardsGroupedByLetter: FC<CardsGroupedByLetterProps> = ({
  cards,
  setModalAction,
  setCardAdminModalOpen,
  setSelectedCard,
}) => {
  const sections = Object.entries(cards)

  const displaySections = sections.map((section, index) => {
    return (
      <div key={index} className="mb-4 w-full border-b border-gray-200 pb-10">
        <p className="mb-2 text-xl font-bold text-indigo-600">
          {section[0].toUpperCase()}
        </p>
        <div
          className="grid gap-4"
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          }}
        >
          {section[1].map((card, index) => (
            <div className="flex-grow p-1" data-action="drag-card" key={index}>
              <div
                className={
                  'group relative flex h-[180px] flex-col items-center justify-around overflow-hidden rounded-lg bg-white p-2 text-center shadow'
                }
              >
                <div className="absolute top-0 m-1 block flex w-[95%] justify-between rounded group-hover:bg-gray-100">
                  <div className="flex">
                    <span className="p-1 px-2 text-gray-400 group-hover:text-gray-600">
                      {index + 1}
                    </span>
                    <span className="opacity-0 group-hover:opacity-100"></span>
                  </div>
                  <div className="top-0 flex flex-row opacity-0 group-hover:opacity-100">
                    <Button
                      variant="text"
                      className="min-w-min !rounded-r py-0"
                      onClick={() => {
                        setModalAction('edit')
                        setSelectedCard(card)
                        setCardAdminModalOpen(true)
                      }}
                    >
                      <PencilIcon className="h-4 w-4 text-gray-600" />
                      <span className="sr-only">Edit</span>
                    </Button>
                  </div>
                </div>

                <div className="flex select-none flex-col items-center justify-center rounded pt-4">
                  {card?.iconStorageObject?.downloadUrl ? (
                    <div
                      className="mx-auto h-12 w-12 rounded"
                      style={{
                        backgroundImage:
                          'url(' + card?.iconStorageObject?.downloadUrl + ')',
                        backgroundPosition: 'center center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                      }}
                    ></div>
                  ) : (
                    <div className="mx-auto h-12 w-12 rounded bg-gray-200"></div>
                  )}
                  <p
                    id={`card-id-${card?.id}`}
                    className="py-2 text-sm"
                    data-testid={`card-name-${card?.name}`}
                  >
                    {card?.name}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  })

  return <>{displaySections}</>
}

const DefaultHomeLinks: FC<DefaultHomeLinksProps> = ({
  cards,
  refetch,
  globalIcons,
}) => {
  // SET STATE
  const [currentlySaving, setCurrentlySaving] = useState(false)
  const [currentlyDeleting, setCurrentlyDeleting] = useState(false)
  const [modalAction, setModalAction] = useState('')
  const [cardAdminModalOpen, setCardAdminModalOpen] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)

  // GROUP CARDS
  const cardsOrganisedByFirstCharacter = cards.reduce(
    (organisedCards, currentCard) => {
      // Get the first character of card name
      const firstChar = currentCard?.name.charAt(0).toLocaleLowerCase()

      // If the char doesn't exist yet, set it as an array
      organisedCards[firstChar] || (organisedCards[firstChar] = [])

      // Push card to obj key of first letter
      organisedCards[firstChar].push(currentCard)

      return organisedCards
    },
    {},
  )

  const cardsSortedByFirstCharacter = Object.keys(
    cardsOrganisedByFirstCharacter,
  )
    .sort()
    .reduce((obj, key) => {
      obj[key] = cardsOrganisedByFirstCharacter[key]
      return obj
    }, {})

  // CREATE CARD
  const [createCardItemMutation] = useMutation<
    CreateInformerListCardTemplateMutation,
    CreateInformerListCardTemplateMutationVariables
  >(CREATE_CARD_TEMPLATE_ITEM, {
    onCompleted: async () => {
      toast.success('Card Created', {
        duration: 2000,
        className: 'flex-column',
      })

      await refetch()
      setCardAdminModalOpen(false)
      setCurrentlySaving(false)
    },
    onError: (error) => {
      toast.error(' Error creating Card \n \n' + error.message, {
        duration: 5000,
        className: 'flex-column',
      })
      setCurrentlySaving(false)
    },
  })

  function createCardItem(args: { data: any }) {
    // Get the icon ID
    const iconStorageObjectId = args.data?.iconStorageObject?.id
      ? Number(args.data?.iconStorageObject?.id)
      : null

    createCardItemMutation({
      variables: {
        input: {
          name: args.data.name,
          iconStorageObjectId: iconStorageObjectId,
          url: args.data.url,
          isEmbed: args.data.isEmbed,
          isTemplate: true,
        },
      },
    })
  }

  const submitCardForm = (values) => {
    const cardArgs = {
      data: {
        name: values.data.name,
        iconStorageObject: values.data.iconStorageObject,
        url: values.data.link,
        isEmbed: values.data.isEmbed,
      },
    }

    // Create a new Card
    if (modalAction === 'create') {
      setCurrentlySaving(true)
      createCardItem(cardArgs)
    }

    // Update the selected card
    if (modalAction === 'edit') {
      setCurrentlySaving(true)
      updateCardItem(cardArgs)
    }
  }

  // DELETE CARD
  const [deleteCardItemMutation] = useMutation(DELETE_CARD_TEMPLATE_ITEM, {
    onCompleted: async () => {
      toast.success('Card Deleted', {
        duration: 2000,
        className: 'flex-column',
      })
      await refetch()
      setCurrentlyDeleting(false)
      setCardAdminModalOpen(false)
    },
    onError: (error) => {
      toast.error('Error deleting your card \n \n' + error.message, {
        duration: 5000,
        className: 'flex-column',
      })
      setCurrentlyDeleting(false)
    },
  })

  function deleteCardItem(id: number) {
    // Now deleting
    setCurrentlyDeleting(true)

    // Delete card by ID
    deleteCardItemMutation({
      variables: {
        id: id,
        clientId: PROD_CLIENT.STAFFLINK,
      },
    })
  }

  // UPDATE CARD
  const [updateCardItemMutation] = useMutation<
    UpdateInformerListCardTemplateMutation,
    UpdateInformerListCardTemplateMutationVariables
  >(UPDATE_CARD_TEMPLATE_ITEM, {
    onCompleted: async () => {
      toast.success('Card Updated', {
        duration: 2000,
        className: 'flex-column',
      })

      await refetch()
      setCurrentlySaving(false)
      setCardAdminModalOpen(false)
    },
    onError: (error) => {
      toast.error(' Error updating Card \n \n' + error.message, {
        duration: 5000,
        className: 'flex-column',
      })
      setCurrentlySaving(false)
    },
  })

  async function updateCardItem(args: { data: any }) {
    // Get the icon ID
    const iconStorageObjectId = args.data?.iconStorageObject?.id
      ? Number(args.data?.iconStorageObject?.id)
      : null

    // The existing value
    const existingIconID = selectedCard.iconStorageObject

    // Confirm what will be saved to DB
    // If new (not null) -> save that, otherwise run with existing, which can be number or null
    const iconIdToSave = iconStorageObjectId ?? existingIconID

    const result = await updateCardItemMutation({
      variables: {
        id: selectedCard.id,
        input: {
          name: args.data.name,
          iconStorageObjectId: iconIdToSave,
          url: args.data.url,
          order: parseInt(args.data.order),
          isAll: args.data.isAll,
          isEmbed: args.data.isEmbed,
          informerSectionId: parseInt(args.data.section),
        },
      },
    })

    return result
  }

  // FUNCTIONS
  const openBlankCardModal = () => {
    setCardAdminModalOpen(true)
    setModalAction('create')
  }

  const { trackEvent } = useAnalytics()

  return (
    <>
      <div className="flex w-full items-center justify-end p-10 pb-0">
        <Button
          buttonDataTestId="default-home-links-add-card"
          fullWidth={false}
          onClick={() => {
            openBlankCardModal()
            trackEvent('Settings', 'Create Default Card')
          }}
          startIcon={<PlusIcon className="h-4 w-4" aria-hidden="true" />}
        >
          Create default card
        </Button>
      </div>
      <div className="flex flex-wrap gap-4 p-10" id="defaultCardsGrid">
        {cards.length > 0 ? (
          <CardsGroupedByLetter
            cards={cardsSortedByFirstCharacter}
            setCardAdminModalOpen={setCardAdminModalOpen}
            setSelectedCard={setSelectedCard}
            setModalAction={setModalAction}
          />
        ) : (
          <NoCardsFound />
        )}
      </div>
      <CardAdminModal
        selectedSection={{}}
        informerSections={[]}
        membershipGroups={[]}
        members={[]}
        cardAdminModalOpen={cardAdminModalOpen}
        setCardAdminModalOpen={setCardAdminModalOpen}
        submitCardForm={submitCardForm}
        modalAction={modalAction}
        setModalAction={setModalAction}
        currentlySaving={currentlySaving}
        currentlyDeleting={currentlyDeleting}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        deleteCardItem={deleteCardItem}
        hideSectionOption={true}
        hideMemberOption={true}
        hideGroupOption={true}
        isGlobalIcon={true}
        globalIcons={globalIcons}
      />
    </>
  )
}

export default DefaultHomeLinks
