import { PROD_CLIENT } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import DefaultHomeLinksCell from 'src/components/DefaultHomeLinksCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const DefaultHomeLinksPage = () => {
  return (
    <>
      <Metadata
        title="Default Home Links"
        description="Create, Edit and Delete default card options for home links."
      />
      <PageHeader title="Default Home Links" />
      <DefaultHomeLinksCell
        clientId={PROD_CLIENT.STAFFLINK}
        isTemplate={true}
        fileNames={['asset-library-icon']}
      />
    </>
  )
}

export default DefaultHomeLinksPage
